import React from 'react'
import Head from 'next/head'
import dynamic from 'next/dynamic'
const LoginPage = dynamic(() => import('../components/login-page'), { ssr: false })

export default function Login() {
  return (
    <div className="login">
      <Head>
        <title>Plottr</title>
        <meta name="description" content="Plottr" />
        <link rel="apple-touch-icon" sizes="76x76" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
      </Head>
      {/* @ts-ignore */}
      <LoginPage />
      <footer className="login-footer"> </footer>
    </div>
  )
}
